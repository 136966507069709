import React from "react";
import { ReactComponent as B } from "assets/images/logo/b.svg";
import { ReactComponent as R } from "assets/images/logo/r.svg";
import { ReactComponent as E } from "assets/images/logo/e.svg";
import { ReactComponent as A } from "assets/images/logo/a.svg";
import { ReactComponent as D } from "assets/images/logo/d.svg";
import { ReactComponent as L } from "assets/images/logo/l.svg";
import { ReactComponent as Y } from "assets/images/logo/y.svg";
import classNames from "classnames";
import "components/AnimatedLogo/AnimatedLogo.scss";

type Props = {
  className?: string;
};

const AnimatedLogo = ({ className }: Props) => {
  return (
    <div className={classNames("breadly-logo", className)}>
      <div id="B">
        <B />
      </div>
      <div id="R">
        <R />
      </div>
      <div id="E">
        <E />
      </div>
      <div id="A">
        <A />
      </div>
      <div id="D">
        <D />
      </div>
      <div id="L">
        <L />
      </div>
      <div id="Y">
        <Y />
      </div>
    </div>
  );
};

export default AnimatedLogo;
