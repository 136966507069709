import React from "react";
import AnimatedLogo from "components/AnimatedLogo";
import Twitter from "assets/images/socials/twitter.png";

const Header = () => {
  return (
    <div className="w-full flex items-center justify-between sm:h-[7.5rem] relative z-10 -top-[5rem] sm:-top-[5.5rem] pl-6 pr-4 sm:pl-6 sm:pr-6">
      <AnimatedLogo className="h-[7.5rem] lg:translate-x-10 !scale-75 lg:!scale-100 -translate-y-0 sm:-translate-y-4" />
      <a
        href="https://twitter.com/Breadlyverse"
        className="drop-shadow-logo -rotate-[2deg] group"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={Twitter}
          alt="twitter logo"
          className="h-[4.9375rem] w-[6.75rem] group-hover:animate-bounce"
        />
      </a>
    </div>
  );
};

export default Header;
