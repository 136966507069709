import DefaultLayout from "layout/DefaultLayout";
import React from "react";
import OVVIE from "assets/images/blank.png";

const Home = () => {
  return (
    <DefaultLayout>
      <div className="container px-4 sm:px-20 xl:px-40 flex flex-col items-center justify-between w-full h-full">
        <p className="text-skrapbook text-brown text-center text-5xl pt-12">
          YUMMING SOON!
        </p>
        <div className="flex items-center justify-center flex-col pt-12 pb-40">
          <img src={OVVIE} className="w-[25rem]" alt="" />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Home;
