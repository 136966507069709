import React, { ReactNode } from "react";
import AnimatedBackgroundV2 from "components/AnimatedBackground";
import AnimatedBackgroundContextProvider from "context/AnimatedBackgroundContext";
import Header from "components/modules/home/Header/Header";

const BG_BACKGROUND = "/images/bread_background.jpg";
const BG_TOP = "/images/bread_top.png";
const BG_BOTTOM = "/images/bread_bottom.png";

type Props = {
  children: ReactNode;
};

function Container({ children }: Props) {
  return (
    <div
      className="breadly-container"
      style={{
        backgroundImage: `url(${BG_BACKGROUND})`,
      }}
    >
      <div
        className="bg-before"
        style={{
          backgroundImage: `url(${BG_TOP})`,
        }}
      />
      <Header />
      <div className="-mt-[6.75rem] sm:-mt-[6.2rem] overflow-hidden flex-grow">
        {children}
      </div>
      <div
        className="bg-after"
        style={{
          backgroundImage: `url(${BG_BOTTOM})`,
        }}
      />
    </div>
  );
}

function DefaultLayout({ children }: Props) {
  return (
    <div className="breadly sm:overflow-hidden">
      <AnimatedBackgroundContextProvider>
        <div className="overflow-hidden sm:overflow-visible">
          <AnimatedBackgroundV2 ROW_COUNT={5} />
        </div>
        <Container>{children}</Container>
        <div className="relative sm:mt-0 overflow-hidden sm:overflow-visible">
          <AnimatedBackgroundV2 ROW_COUNT={6} />
        </div>
      </AnimatedBackgroundContextProvider>
    </div>
  );
}

export default DefaultLayout;
