import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import debounce from "lodash.debounce";
import useWindowDimensions from "hooks/useDimensions";
import {
  AnimationType,
  ANIMATION_DELAY,
  ANIMATION_DURATION,
  DOTS_MARGIN_X,
  DOTS_SIZE,
  GAPY,
} from "constants/ANIMATED_BACKGROUND";
import { BLUE, RED, YELLOW } from "constants/COLORS";
import { AnimatedBackgroundContext } from "context/AnimatedBackgroundContext";
import "components/AnimatedBackground/AnimatedBackground.scss";

type Props = {
  ROW_COUNT?: number;
  animate?: boolean;
};

const convertRemToPixels = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

const AnimatedBackgroundV2 = ({ animate = true, ROW_COUNT = 12 }: Props) => {
  const { width: clientWidth } = useWindowDimensions();
  const [windowWidth, setWindowWidth] = useState(clientWidth);
  const BackgroundContext = useContext(AnimatedBackgroundContext);
  const animationName =
    BackgroundContext?.animationName || AnimationType.scaleIn;
  const currentAnimationIsScaleIn = animationName === AnimationType.scaleIn;
  const SIZE = convertRemToPixels(DOTS_SIZE);
  const MARGIN_X = convertRemToPixels(DOTS_MARGIN_X);
  const MARGIN_Y = convertRemToPixels(GAPY);

  const DOTS_COUNT = useMemo(() => {
    if (!windowWidth) return 0;
    return Math.ceil(windowWidth / (SIZE - MARGIN_X * 2)) + 1;
  }, [windowWidth, SIZE, MARGIN_X]);

  const DOTS = useMemo(() => {
    return Array.from({ length: DOTS_COUNT }).map((u, index) => {
      if (index % 3 === 0) {
        return BLUE;
      }
      if (index % 3 === 1) {
        return RED;
      }
      return YELLOW;
    });
  }, [DOTS_COUNT]);

  const onAnimationEnd = useCallback((): void => {
    BackgroundContext?.setAnimatedEndCount(
      (currentValue) => (currentValue || 0) + 1
    );
  }, [BackgroundContext]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceWidth = useCallback(
    debounce((newWidth): void => {
      setWindowWidth(newWidth);
    }, 1000),
    [debounce]
  );

  useEffect((): void => {
    if (animate) {
      setWindowWidth(0);
      debounceWidth(clientWidth);
    } else {
      setWindowWidth(clientWidth);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientWidth, animate]);

  return (
    <div
      className="flex -mx-2 -mt-2"
      style={{
        minHeight: ROW_COUNT * (SIZE + MARGIN_Y) - MARGIN_Y,
      }}
    >
      {DOTS.map((color, dotsIndex) => {
        const isOdd = dotsIndex % 2 !== 0;

        return (
          <div
            className="flex flex-col z-[-1]"
            key={dotsIndex}
            style={{
              marginTop: isOdd ? -22 : 0,
              rowGap: MARGIN_Y,
            }}
          >
            {Array.from({ length: ROW_COUNT }, (v, i: number) => i).map(
              (dot: number) => {
                const FADEIN_DELAY: number =
                  (Math.floor(dotsIndex / 3) + dot) * ANIMATION_DELAY;
                const DOTS_LENGTH: number = DOTS_COUNT - 1;
                const ROWS_LENGTH: number = ROW_COUNT - 1;

                let animationProps = {};

                if (animate) {
                  animationProps = {
                    animationName,
                    animationDelay: `${animate ? FADEIN_DELAY : null}ms`,
                    animationDuration: `${
                      animate ? ANIMATION_DURATION : null
                    }ms`,
                    animationFillMode: "forwards",
                    transform: `scale(${currentAnimationIsScaleIn ? 0 : 1})`,
                  };
                }

                return (
                  <div key={`${dotsIndex}-${dot}`}>
                    <div
                      className="rounded-full"
                      style={{
                        height: SIZE,
                        width: SIZE,
                        backgroundColor: color,
                        marginLeft: `-${MARGIN_X}px`,
                        marginRight: `-${MARGIN_X}px`,
                        ...animationProps,
                      }}
                      onAnimationEnd={
                        DOTS_LENGTH === dotsIndex &&
                        ROWS_LENGTH === dot &&
                        animate
                          ? onAnimationEnd
                          : null
                      }
                    />
                  </div>
                );
              }
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AnimatedBackgroundV2;
