export const DOTS_SIZE: number = 0.9375;
export const ANIMATION_DURATION: number = 250;
export const ANIMATION_DELAY: number = 125;
export const DOTS_MARGIN_X: number = 0.0625;
export const BLUE: string = "#0000ff";
export const RED: string = "#ff0000";
export const YELLOW: string = "#ffff00";
export const COLORS: string[] = [BLUE, RED, YELLOW];
export const GAPY: number = 1.75;
export enum AnimationType {
  scaleIn = "scaleIn",
  scaleOut = "scaleOut",
}
