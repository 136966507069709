import { AnimationType } from "constants/ANIMATED_BACKGROUND";
import { createContext, useState, useEffect, ReactNode } from "react";

export const AnimatedBackgroundContext = createContext(null);

type Props = {
  children: ReactNode;
  instance?: number;
};

function AnimatedBackgroundContextProvider({ children, instance = 2 }: Props) {
  const [animationName, setAnimationName] = useState(AnimationType.scaleIn);
  const [animatedEndCount, setAnimatedEndCount] = useState(0);

  useEffect(() => {
    if (animatedEndCount === instance) {
      setAnimationName((currentValue) =>
        currentValue === AnimationType.scaleIn
          ? AnimationType.scaleOut
          : AnimationType.scaleIn
      );
      setAnimatedEndCount(0);
    }
  }, [animatedEndCount, instance]);

  return (
    <AnimatedBackgroundContext.Provider
      value={{
        animationName,
        setAnimatedEndCount,
      }}
    >
      {children}
    </AnimatedBackgroundContext.Provider>
  );
}

export default AnimatedBackgroundContextProvider;
